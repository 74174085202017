import React, {useState, useContext} from 'react';
import axios from 'axios';
// import 'react-toastify/dist/ReactToastify.css';
import {  Button } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'; 
import {VoicemateDataContext} from '../context/DataContext';


const Form = (props) => {
  const [msg, setMsg] = useState (null);
  const [errmsg, setErrMsg] = useState (null);

  
  
  const voicemateAction = useContext(VoicemateDataContext);
  const language = voicemateAction?.language
     
  const { t } = props

  
  const [loading, setLoading] = useState(false);


  const url = "https://contactforms.thevoicemate.com/contact-us/ "
 


  const [userRegistration, setUserRegistration] = useState({
      name: "",
      email: "",
      phone: "",
      subject: "",
      address: "",
      language: "",
      identifier: "Voicemate The Validator"
  });


  const handleInput = (e) => {

      const name = e.target.name;
      
      const value = e.target.value;
     
      setUserRegistration({ ...userRegistration, [name]: value })
    
      
  }

  const [records, setRecords] = useState([]);

  const handleSubmit =  (e) => {
    e.preventDefault();
  
      const newRecord = { ...userRegistration}
      setRecords([ ...records, newRecord]);


     
      setLoading(true);
      
       axios.post(url, {
      name: userRegistration.name,
      email: userRegistration.email,
      phone: userRegistration.phone,
      subject: userRegistration.subject,
      address: userRegistration.address,
      language,
      identifier: userRegistration.identifier
      })

      
      
     .then(function (response) {
      if(response.status === 200){
        console.log(response);
        setMsg(response.data.msg)
        setLoading(false);     
        setUserRegistration({name:"", email:"", phone: "", language: "",  identifier: "Voicemate The Validator", subject: "", address: ""})


      }
     
      
  })
      .catch(function (error) {
          setLoading(false);
          console.log(error.response);
          console.log(error.request);
          console.log(error);
          setMsg(error.response.data.msg)
         
       });
            

        
  }


    return (
        <div class="contact_bg pb-5">
    
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                     <div class="forms">
                <p>{t('contact_desc')} </p>
              
                <form onSubmit={(e)=>handleSubmit(e)}>
                <div class="row">
                   
                      
                      <div class="mb-3 mt-3">
                      <label className="font-700">{t('contact_name')} <span className="red">*</span></label>
                      <input type="text" value={userRegistration.name} onChange={handleInput} 
                       className="form-control" name="name"   placeholder={t('contact_name')} required />
                      </div>

                      <div class="mb-3">
                      <label className="font-700">{t('contact_number')} <span className="red">*</span></label>
                      <input type="number" className="form-control"
                    name="phone"
                    value={userRegistration.phone} onChange={handleInput} 
                     placeholder={t('contact_number')} required />
                      </div>

                      <div class="mb-3">
                      <label className="font-700">{t('contact_email')} <span className="red">*</span></label>
                      <input type="email"
                    name="email"
                    value={userRegistration.email} onChange={handleInput} 
                     className="form-control" placeholder={t('contact_email')} required />
                      </div>

                      <div class="mb-3">
                      <label className="font-700">{t('contact_subject')} <span className="red">*</span></label>
                      <input type="text" className="form-control" 
                      name="subject"
                      value={userRegistration.subject} onChange={handleInput} required 
                       placeholder={t('contact_subject')} />
                      </div>

                      <div class="mb-3">
                      <label className="font-700">{t('contact_address')} </label>
                      <input type="text" className="form-control" 
                      name="address"
                      value={userRegistration.address} onChange={handleInput} 
                       placeholder={t('contact_address')}  />
                      </div>


                      <div class="mb-3">
                      <input type="hidden" className="form-control" 
                      name="identifier"
                      value={userRegistration.identifier} onChange={handleInput} 
                       placeholder="identifier" />  

                    
                       
                { msg &&
                  <div class="alert alert-success">
                  {msg}
                  </div>
                  } 

               

                      { errmsg &&
                        <div class="alert alert-danger">
                  {errmsg}
                  </div>
                      }

                      
                    
                       
                    </div>

                    <div class="col-lg-3 col-12">
                    <Button  loading={loading}
                   type="submit" appearance="primary ">{t('submit')}</Button>
                
                        
                    </div>
                    </div>
                </form>
            </div>
                </div>
            </div>
           
        </div>
    </div>

    )
}

export default Form
