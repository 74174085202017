
   
import React from 'react';
import Form from './Form'
import { Link } from 'react-router-dom';

const title = {
   display:'none',
  };

const Home = (props) => {
    const { t, i18n } = props
   

    return (
        <section>
     <div class="first_start pt-5">
        <div class="container">
            <div class="row">

              <div class="col-12 d-block d-lg-none">
                    <div class="vla">
                        <img src={t('main_img')} alt="" />
                    </div>
                </div>
                
                <div class="col-lg-6 col-12">
                    <div class="hello-content">
                        <div class="hello">
                            <span>{t('main_title')}</span>
                            <h4>{t('sub_title')} </h4>
                        </div>
                        <div class="pac">                         
                            <div class="about">
                                <a target="_blank" href="https://thevoicemate.com/">
                                <div className="iconi"><img src="img/aboutus.png" /></div>
                                <p>{t('head2')}</p></a>
                            </div>

                            <div class="pricing">
                                <Link to="/pricing">
                                <div className="iconi"><img src="img/pencil.png" /></div>
                                <p>{t('head1')}</p>
                                </Link>
                            </div>
                          
                            <div class="contact">
                                <Link  to="/contact">
                                <div className="iconi"><img src="img/contact us.png" /></div>
                                <p>{t('head3')}</p>
                                </Link>
                            </div>
                          
                        </div>
                         <div class="des">
                         <p style={{fontWeight: '700'}}>{t('sub_title2')}</p>
                            <p>{t('first_desc')}</p>
                        </div>
                        
                       
                    </div>
                </div>

              

            </div>
        </div>
    </div>

    <div class="process bg-3">
        <div class="container-fluid">
        <div class="row">
         <div class="col-lg-7 col-sm-12 col-md-12 col-12">
            <div class="verify">
                <img src={t('how_img')} alt="" />
            </div>
        </div>

        <div class="col-lg-5 col-sm-12 col-md-12 col-12">
            <div class="hdiw">
                <h4 className="pb-lg-4 pb-2">{t('how_works_title')} </h4>
                <p> <strong>VoiceMate</strong> {t('how_work-desc')}.</p>

                <ul class="work">
                    <li><span>&#x203A;</span>{t('work_point1')}</li>
                    <li><span>&#x203A;</span>{t('work_point2')}</li>
                    <li><span>&#x203A;</span>{t('work_point3')}
                    <strong>
                        "Yes"
                    </strong>.</li>
                    <li><span>&#x203A;</span>{t('work_point4')}</li>
                   
                </ul>
            </div>
        </div>

       

        </div>
            
            
        </div>
    </div>

    <div class="video pb-lg-5 pb-3">
        <div class="container-fluid">
        <div class="row">
        <div class="col-lg-6">
            <div class="hdiw">
                <h4 className='pb-lg-4 pb-2'>{t('valid_action_title')}</h4>
                <p className="pb-lg-3 pb-2">{t('valid_action_desc')}</p>
            </div>
        </div>

         <div class="col-lg-6">
            <div class="verify">
            <video playsinline="playsinline" muted="muted" preload="yes" autoplay="autoplay" loop="loop" controls="controls" id="vjs_video_520_html5_api" class="video-js" data-setup='{"autoplay":"any"}'>
    		<source src="./img/file.mp4" type="video/mp4" />
      </video>
               
            </div>
        </div>
        
       
            
        </div>
            
            
        </div>
    </div>

    <div class="benifets pb-5">
        <div class="container">
            <div class="hdiw">
                <h4 className="pb-lg-4 pb-2">{t('benifts_title')}</h4>
                <p className="pb-lg-3 pb-2 text-center">{t('benifts_desc')}
                </p>
            </div>
            <div class="benifits">
                <img src={t('benifts_img')} alt="" />
            </div>
        </div>
    </div> 


     <div>
    <div class="hdiw">
         <h4 className="pb-lg-4 pb-2">{t('contact_title')}</h4>
     </div>
    <Form  t={t} i18n={i18n} />
    </div>

    </section>  
        
    )

};

export default Home;


   

    

    

    