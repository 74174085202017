
import React from 'react';
import Header from './pages/Header';
import Home from './pages/Home';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Footer from './pages/Footer';
import {Switch, Route} from 'react-router-dom';
import i18n from "./i18next";
import { useTranslation } from "react-i18next";
import {VoicemateDataProvider} from './context/DataContext';


const App = () => {
  const { t } = useTranslation()
  return (
    <>
    <VoicemateDataProvider>
    <Header t={t} i18n={i18n} />
    
    <Switch>
    <Route exact path="/" component={()=><Home t={t} i18n={i18n} />} />
    <Route exact path="/pricing"  component={()=><Services t={t} i18n={i18n} />} />
    <Route exact path="/contact" component={()=><Contact t={t} i18n={i18n} />} />
   
    </Switch> 
    </VoicemateDataProvider>

   <Footer />
   </>
  );
}

export default App;
