

import React, {useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import {VoicemateDataContext} from '../context/DataContext';


const Header= (props) => {
   const voicemateAction = useContext(VoicemateDataContext);
   
    const [isClicked, setClicked] = useState(true)

    const { t, i18n } = props

    const changelanguage = (ln) => {
        return () => {
            i18n.changeLanguage(ln);
            console.log("I am change language", ln)
        }
    }
    function changeContextLanguage(lang) {
        // changelanguage(lang)
        i18n.changeLanguage(lang);
        voicemateAction.setLanguage(lang)
    }


    return(

 <div class="headbg">
        <div class=" container">
        <b className="d-flex">Powered By:</b>
            <div class="head">
                <div class="site-logo">
              
              
                   <Link to="/">
                   <img src="img/logo.png" alt="" /></Link>
                </div>
                <div class="vmt">
                    <h4>The Validator</h4>
                    <p>{t('head_desc')}</p>
                </div>
                <div  className="devicelang">
                
                <span className="d-none d-md-block">Change Language</span>   
					{isClicked && 
				<button className="lang-btn  mr-2"  onClick={() => changeContextLanguage("en")}>En</button>
				}
				{isClicked && 
				<button className="lang-btn " onClick={() => changeContextLanguage("vi")}>Vi</button>
				}
				</div>
            </div>
        </div>
    </div>

    )
};

export default Header;




