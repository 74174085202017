

import React from 'react';


const Footer = () => {


    return (


        <div class="footer" id="contact">
        <div class="container">
            <div class="contact-info">
                <div class="call-info">
                    <div class="ficon">
                        <i class="fa fa-phone"></i>
                    </div>
                    <div class="phone-info">
                        <a href="tel:+84939469074">+84939469074</a>
                    </div>
                </div>
                <div class="call-info">
                    <div class="ficon">
                        <i class="fa fa-envelope"></i>
                    </div>
                    <div class="phone-info">
                        <a href="mailto:info@thevoicemate.com">info@thevoicemate.com</a>
                    </div>
                </div>
                <div class="call-info">
                   
                    <div class="social-icon">
                        <a href="https://www.facebook.com/thevoicemate"><i class="fa fa-facebook"></i></a>
                        <a href="https://www.youtube.com/channel/UCfyddqKzwlHSWRGaIabqY8Q"><i class="fa fa-youtube"></i></a>
                        <a href="https://www.instagram.com/voice_mate/"><i class="fa fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/voicemate"><i class="fa fa-linkedin"></i></a>
                    </div>
                </div>
                
            </div>
            <div class="add-nfo">
                <p>Chính sách Bảo mật / Bản quyền © 2021 VOICEMATE. Đã đăng ký Bản quyền <br />
                    Thông tin liên hệ <br />
                    Công Ty TNHH Công Nghệ VOICEMATE <br />
                    Giấy chứng nhận đăng ký kinh doanh số 0316715597 <br />
                    Do Sở KH & ĐT Thành phố Hồ Chí Minh cấp ngày 17/02/2021</p>
            </div>
            <div class="f-logo">
                <img src="img/moit Logo.webp" alt="" />
            </div>

            <div class="copyright pt-4">
                    <div class="phone-info">
                        <h6 align="center">Copyright &#xa9; 2021 by The Voicemate.</h6>
                    </div>
                </div>
        </div>
    </div>




    )

};

export default Footer;





