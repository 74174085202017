import React from 'react';
import { Link } from 'react-router-dom';


const Services = (props) => {
    const { t } = props



  return (
      <>
   <div class="title">
        <div class="container">
             <div class="h_contact pt-3">
                <h4>Pricing</h4>
            </div>
           
        </div>
         <hr />
 </div>

<div class="container price">
        <div class="row">
            <div class="col-lg-4 col-12">
            <div class="pricing1">
                <i class="fa fa-tags"></i>
                <h4>{t('pricing_1')}</h4>
                <h2>{t('call_1')}</h2>
                <ul className="arrow">
                   <li>{t('pricing_point1')}</li>
                   <li>{t('pricing_point2')}</li>
                   <li>{t('pricing_point3')}</li>
                   <li>{t('pricing_point4')}</li>
                   <li>{t('pricing_point5')}</li>
                   <li><strong>{t('pricing_price1')}</strong></li>
                </ul>
               
                <br />
                <Link to="/Contact" class="buy btn-custom d-flex align-items-center justify-content-center">{t('buy_nowbtn')}</Link>
                </div>
            </div>
            <div class="col-lg-4 col-12">
            <div class="pricing2">
                <i class="fa fa-star"></i>
                <h4>{t('pricing_2')}</h4>
                <h2>{t('call_2')}</h2>
                <ul className="arrow">
                <li>{t('pricing_point1')}</li>
                   <li>{t('pricing_point2')}</li>
                   <li>{t('pricing_point3')}</li>
                   <li>{t('pricing_point4')}</li>
                   <li>{t('pricing_point5')}</li>
                   <li><strong>{t('pricing_price2')}</strong></li>
                </ul>
                <br />
                <Link to="/Contact" class="buy btn-custom d-flex align-items-center justify-content-center">{t('buy_nowbtn')}</Link>
                </div>
            </div>
            <div class="col-lg-4 col-12">
            <div class="pricing3">
                <i class="fa fa-rocket"></i>
                <h4>{t('pricing_3')}</h4>
                <h2>{t('call_3')}</h2>
                <ul className="arrow">
                <li>{t('pricing_point1')}</li>
                   <li>{t('pricing_point2')}</li>
                   <li>{t('pricing_point3')}</li>
                   <li>{t('pricing_point4')}</li>
                   <li>{t('pricing_point5')}</li>
                   <li><strong>{t('pricing_price3')}</strong></li>
                </ul>
                <br />
                <Link to="/Contact" class="buy btn-custom d-flex align-items-center justify-content-center">{t('contact_nowbtn')}</Link>
                </div>
            </div>
        </div>
    </div>

   </>


  )

};

export default Services;

    



    